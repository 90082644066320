.socialMediaLink {
  color: #ffffff;
}

.socialMediaLink:hover {
  color: #1da1f2;
}

.serviceCard {
  background-color: rgba(250, 250, 248, 0.62);
  border: 0 solid #000;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  display: flex;
  max-width: 500px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.08);
}

.serviceTitle {
  color: #333;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
}

.serviceText {
  color: #8a8a8a;
  max-width: 700px;
  margin-bottom: 0;
  font-family: Playfair Display, sans-serif;
  font-size: 16px;
  line-height: 30px;
}

.dayContainer {
  position: relative;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.08);
  color: white;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
  width: 300px;
  z-index: 1;
}

.dayContainerTime {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
  z-index: 10;
  font-size: 32px;
  font-weight: bold;
}

.dayContainerTitle {
  position: absolute;
  top: 8px;
  right: 16px;
  z-index: 20;
  font-size: 32px;
  font-weight: bold;
  color: white;
}
