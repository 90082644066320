@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@100;400;700&family=Lato:wght@100;300;400;700&family=Great+Vibes&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Playfair Display", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mantine-Drawer-body {
  padding: 0px !important;
  height: 100%;
}
