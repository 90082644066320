.link {
  color: var(--mantine-color-white-0);
  text-decoration: none;
  display: block;
  transition: all 0.2s ease;
  font-family: "Playfair Display, sans-serif";
  padding: 18px;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.logo {
  animation: slideInFromLeft 0.6s ease-out forwards;
}

.button {
  animation: slideInFromRight 0.6s ease-out forwards;
}

.links {
  animation: slideInFromRight 0.6s ease-out forwards;
}
